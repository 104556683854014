

import api from '@/api/api';
import { store } from '@/store';
import { M_MENU } from '@/store/mutations-types';
import { defineComponent } from 'vue';
import VueApexCharts from 'vue-apexcharts';
import selectOnShift from '@/utils/selectOnShift';

export default defineComponent({
    name: 'DashPlanejadoRealizadoView',
    components: {
        apexchart: VueApexCharts
    },
    data() {
        return {
            search: '',
            exibeFiltro: false,            
            sprintFiltro: [],            
            dsSprintFiltro: [],
            sprintsToFilter: [],
            dsEmergentes: [],
            dsProjetos: [],
        }
    },
    computed: {
        corLabel (){
            let cor
            if (this.$vuetify.theme.dark){
                cor = '#FFFFFF'
            } else {
                cor = '#000000'
            }
            return cor
        },
        optionsEmergentesProjetos () {
            return {
                chart: {    
                    type: 'area',
                    foreColor: this.corLabel,
                    toolbar: {
                        show: true
                    },                                                           
                },   
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: "100%"
                        },
                        legend: {
                        position: 'bottom'
                        }
                    }
                }],
                title: {
                    text: 'Projeto x Emergentes',
                    align: 'center',
                    margin: 0,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize:  '20px',
                        fontWeight:  'bold',                        
                        color: this.corLabel
                    },
                },                  
                legend: {
                    position: 'top'
                },       
                dataLabels: {
                    enabled: true,                    
                }, 
                stroke: {
                    curve: 'smooth',
                },                                    
                labels: this.dsEmergentes.map((x) => {
                    return [x.DESCSPRINT, x.mes]
                }),
                theme: {
                    palette: 'palette5' // upto palette10
                }
            }
        },
        emergentesProjetosSeries () {            
            return  [
                {
                    name: 'Projetos',
                    type: 'area',
                    data: this.dsProjetos.map((x) => parseInt(x.QTD))
                },
                {
                    name: 'Emergentes',
                    type: 'area',
                    data: this.dsEmergentes.map((x) => parseInt(x.QTD))
                }
            ]
        }
        
    },
    methods: {
        selectOnShift,  
        changeExibeFiltro: function () {
            this.exibeFiltro = !this.exibeFiltro
        },        
        changeSprint: async function() {
           if (this.sprintFiltro.length === 0){
                const response = await api.post('/dash/resultadoEmergente')            
                this.dsEmergentes = response.data.dadosEmergentes;
                this.dsProjetos = response.data.dadosProjetos;     
                this.exibeFiltro = !this.exibeFiltro;   
           }                     
           const response = await api.post('/dash/resultadoEmergente', {sprints: this.sprintFiltro})            
           this.dsEmergentes = response.data.dadosEmergentes;
           this.dsProjetos = response.data.dadosProjetos; 
           this.exibeFiltro = !this.exibeFiltro           
        },
        
    },
    async mounted() {
        if (this.dsEmergentes.length === 0 || this.dsProjetos.length === 0) {                        
            const response = await api.post('/dash/resultadoEmergente');
            this.dsEmergentes = response.data.dadosEmergentes;
            this.dsProjetos = response.data.dadosProjetos;            
        }
        if (this.dsSprintFiltro.length === 0) {
            const response = await api.get('/sprints');
            this.dsSprintFiltro = response.data;
            this.sprintsToFilter = this.dsSprintFiltro.map((x) => x.DESCSPRINT)            
        }
        store.commit(M_MENU, `DashBoard Emergente x Projeto`);
    },
})

