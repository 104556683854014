

import api from '@/api/api';
import ModalCrud from '@/components/ModalCrud.vue';
import Grid from '@/components/Grid.vue';
import { store } from '@/store';
import { M_MENU } from '@/store/mutations-types';
import { defineComponent } from 'vue';
import selectOnShift from '@/utils/selectOnShift';

export default defineComponent({
    name: 'DashTrackAnalytic',
    components: {
        Grid,
        ModalCrud    
    },
    data() {
        return {
            loadingGrid: false,
            exibeFiltro: false,
            exibeFiltroData: false,
            search: {
                users: null,
                atividades: null,
                categorias: null,
                modulos: null,
                tags: null
            },
            dataToFilter: {
                option: '1',
                startDate: '', 
                endDate: '',
                users: [],
                description: '',
                atividade: [],
                categoria: [],
                module: [],
                tag: []
            },            
            dsUserToFilter: [],
            dsAtividades: [],
            dsCategorias: [],
            dsModule: [],
            dsTag: [],
            dsTempos: [],
            totalAgrupador: [],
            dataToGroup: [],
            isLoadingFilter: false,
            valid: true,        
            dataIniRules: [
                v => !!v || 'Data inicial precisa ser preenchida.',
            ],
            columns: [
                {
                    text: 'ID',
                    align: 'start',
                    sortable: true,
                    value: 'id',
                },
                {
                    text: 'Responsável',
                    sortable: true,
                    value: 'user',
                },
                {
                    text: 'Descrição',
                    sortable: true,
                    value: 'description',
                },
                {
                    text: 'Atividade',
                    sortable: true,
                    value: 'activity',
                },
                {
                    text: 'Módulo',
                    sortable: true,
                    value: 'module',
                },
                {
                    text: 'Categoria',
                    sortable: true,
                    value: 'category',
                },
                {
                    text: 'Tag',
                    sortable: true,
                    value: 'tag',
                },
                {
                    text: 'Data',
                    sortable: true,
                    value: 'data',
                },
                {
                    text: 'Hora Inicío',
                    sortable: true,
                    value: 'start',
                },
                {
                    text: 'Hora Fim',
                    sortable: true,
                    value: 'end',
                },
                {
                    text: 'Total',
                    sortable: true,
                    value: 'total',
                },
                {
                    text: 'Origem',
                    sortable: false,
                    value: 'origem',
                }
            ],
            totalGeral: [],
        }
    },
    computed: {
        dataFimRules () {
            const rules = []
            if (this.dataToFilter.endDate.length === 0 ){
                const rule = v => !!v || 'Data fim precisa ser preenchida.'
                rules.push(rule)
            } else {                        
                const ruleCheck = (v) => {
                    if(v < this.dataToFilter.startDate) {
                        return 'Data final não pode ser menor que data inicial.'              
                    }
                }                
                rules.push(ruleCheck)                
            }
            return rules;                       
        },  
    },
    methods: {
        selectOnShift,
        changeData: async function (){   
            this.dsTempos = []
            this.loadingGrid = true;
            this.exibeFiltro = false;
            const response = await api.post('/dash/analytictoggl', this.dataToFilter);
            this.updateTracks(response.data)                       
            this.loadingGrid = false;
                           
        },
        updateTracks: function (tracks) {
            this.dsTempos = tracks.resultAnalytic;
            let users = this.dsTempos.map((x) => x.user)            
            let totalizador = this.dsTempos.map((x) => x.TOTAL)            
            let tempos = this.dsTempos.map((x) => ({
                ...x,
                position: users.indexOf(x.user),
            }));                        
            this.dsTempos = tempos;
            this.dataToGroup = users;
            this.totalAgrupador = totalizador;
            this.totalGeral = tracks.total;
        },
    },
    async mounted() {  
        this.loadingGrid = true;      
        if (this.dsTempos.length === 0) {
            this.dataToFilter.option = "1"
            const [response, dataToFilters] = await Promise.all([
                api.post('/dash/analytictoggl', this.dataToFilter),
                api.get('/datafiltertrack')
            ])     

            this.updateTracks(response.data);            
            this.dsUserToFilter = dataToFilters.data.users;
            this.dsAtividades = dataToFilters.data.atividades;
            this.dsCategorias = dataToFilters.data.categoria;
            this.dsModule = dataToFilters.data.modulo;
            this.dsTag = dataToFilters.data.tag;            
        }
        store.commit(M_MENU, `DashBoard Track - Analítico`);
        this.loadingGrid = false;
    },
})

