

import api from '@/api/api';
import Grid from '@/components/Grid.vue'
import ModalCrud from '@/components/ModalCrud.vue';
import { store } from '@/store';
import { M_MENU } from '@/store/mutations-types';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Tarefas',
    components: {
        Grid,
        ModalCrud
    },
    data() {
        return {
            loadingGrid: false,
            idEdit: 0,
            textoModal: '',
            modalAberto: false,
            isLoading: false,
            valid: true,            
            descricao: '',
            descricaoRules: [
                v => !!v || 'Descrição precisa ser preenchida.',
            ],
            status: true,
            cliente: false,
            regiao: false,
            carro: false,
			mostrarTv: false,
            tarefas: [],
            columns: [
                {
                    text: 'ID',
                    align: 'start',
                    sortable: true,
                    value: 'CODTAREFAEXTERNO',
                },
                {
                    text: 'Descrição',
                    sortable: true,
                    value: 'DESCTAREFAEXTERNO',
                },
                {
                    text: 'Envolve Região',
                    sortable: true,
                    value: 'REGIAO',
                },     
                {
                    text: 'Envolve Carro',
                    sortable: true,
                    value: 'CARRO',
                },              
                {
                    text: 'Envolve Cliente',
                    sortable: true,
                    value: 'CLIENTE',
                },
				{
					text: 'Mostrar na TV',
					sortable: true,
					value: 'MOSTRARTV'
				},
                {
                    text: 'Status',
                    sortable: true,
                    value: 'CODIGO_STATUS',
                },
                {
                    text: 'Ações',
                    value: 'actions',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        newTask: function () {
            this.idEdit = 0
            this.modalAberto = true
            this.textoModal = 'Nova Atividade'            
            this.descricao = ''
            this.status = true
            this.cliente = false
            this.carro = false
            this.regiao = false
        },
        editTask: async function (item) {
            this.modalAberto = true
            this.textoModal = 'Editar Atividade'
            const response = await api.get(`/tasks/${item.CODTAREFAEXTERNO}`)
            const { CODTAREFAEXTERNO, DESCTAREFAEXTERNO, CODIGO_STATUS, CLIENTE, CARRO, REGIAO, MOSTRARTV } = response.data          
            this.idEdit = CODTAREFAEXTERNO
            this.descricao = DESCTAREFAEXTERNO,
            this.status = CODIGO_STATUS === 'A' ? true : false
            this.cliente = CLIENTE === 'S' ? true : false            
            this.regiao = REGIAO === 'S' ? true : false            
            this.carro = CARRO === 'S' ? true : false 
			this.mostrarTv = MOSTRARTV === 'S'            
        },
        saveTask: async function(){
            try {
                this.isLoading = true
                if (this.$refs.form.validate()){
                    const [metodo, mensagem] = this.idEdit ? ['put', 'alterada'] : ['post', 'criada'];                
                    const data = {
                        id: this.idEdit,
                        descricao: this.descricao,
                        status: this.status === true ? 'A' : 'I',
                        envolveClient: this.cliente === true ? 'S' : 'N',        
                        regiao: this.regiao === true ? 'S' : 'N',        
                        carro: this.carro === true ? 'S' : 'N',   
						mostrarTv: this.mostrarTv === true ? 'S' : 'N'     
                    }                
                    const operation = await api[metodo]('/tasks', data);
                    const response = await api.get('/tasks');
                    this.tarefas = response.data;
                    this.$toast.success(`Atividade ${mensagem} com sucesso!`);                    
                    this.modalAberto = false;
                } else {
                    this.$toast.warning('Formulário inválido! Verifique os dados.');
                }                   
            } catch (err) {
                this.$toast.error('Falha ao comunicar com o banco de dados!');                
            } finally {
                this.isLoading = false
            }
            
        },
        closeModal: function() {
            this.modalAberto = false,
            this.textoModal = ''
        },
    },
    async mounted() {
        this.loadingGrid = true;
        if (this.tarefas.length === 0) {
            const response = await api.get('/tasks')            
            this.tarefas = response.data
        }
        store.commit(M_MENU, 'Atividades');
        this.loadingGrid = false;
    },
})

