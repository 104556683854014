

import api from '@/api/api';
import ModalCrud from '@/components/ModalCrud.vue';
import SimpleTable from '@/components/SimpleTable.vue';
import { store } from '@/store';
import { M_MENU } from '@/store/mutations-types';
import { defineComponent } from 'vue';
import VueApexCharts from 'vue-apexcharts';
import selectOnShift from '@/utils/selectOnShift';

export default defineComponent({
    name: 'DashTrack',
    components: {        
        SimpleTable,
        apexchart: VueApexCharts,
        ModalCrud
    },
    data() {
        return {
            exibeFiltro: false,
            exibeFiltroData: false,
            search: {
                users: null,
                atividades: null,
                categorias: null,
                modulos: null,
                tags: null
            },
            dataToFilter: {
                option: '1',
                startDate: '', 
                endDate: '',
                users: [],
                description: '',
                atividade: [],
                categoria: [],
                module: [],
                tag: []
            },            
            dsUserToFilter: [],
            dsAtividades: [],
            dsCategorias: [],
            dsModule: [],
            dsTag: [],
            isLoadingFilter: false,
            valid: true,
            dsProjetosDash: [],            
            dsProjetos: [],
            dsModulo: [],
            dsModuloDash: [],
            dsTipo: [],
            dsPercentual: [],                
            dataIniRules: [
                v => !!v || 'Data inicial precisa ser preenchida.',
            ],
            columnsAtividade: [
                {
                    id: 1,
                    columnName: 'Atividade'
                },
                {
                    id: 2,
                    columnName: 'Horas'
                },
                {
                    id: 3,
                    columnName: '%'
                }

            ],
            columnModulo: [
                {
                    id: 1,
                    columnName: 'Módulo'
                },
                {
                    id: 2,
                    columnName: 'Time'
                },
                {
                    id: 3,
                    columnName: '%'
                }

            ]
        }
    },
    computed: {
        corLabel (){
            let cor
            if (this.$vuetify.theme.dark){
                cor = '#FFFFFF'
            } else {
                cor = '#000000'
            }
            return cor
        },        
        optionsBarAtividade () {
            return {
                chart: {
                    type: 'bar',                    
                    foreColor: this.corLabel,           
                    toolbar: {
                        show: true
                    },   
                }, 
                plotOptions: {
                    bar: {
                        borderRadius: 6,
                        horizontal: true,
                        distributed: true
                    }
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: "20px",                        
                        fontWeight: "bold"
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: "100%"
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                legend: {
                    show: false
                },               
                title: {
                    text: 'Tempo x Atividade',
                    align: 'center',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize:  '20px',
                        fontWeight:  'bold',                        
                        color:  this.corLabel
                    },
                },       
                grid: {
                    show: true,
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },  
                    yaxis: {
                        lines: { 
                            show: false
                        }
                    },                       
                },
                colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e', '#f48024','#69d2e7'],                                
                xaxis: {
                    categories: this.dsProjetosDash.map((x) => x.atividade)
                },
                yaxis: {
                    labels: {
                        show: true,
                        style: {
                            fontSize: '14px',
                            fontWeight: "bold"
                        }                        
                    }
                },
                fill: {
                    opacity: 1,
                    
                },
                series: [{
                    data: this.dsProjetosDash.map((x) => parseFloat(x.PERCENT))
                }]
            }
        },
        optionsBarProdutoOutros () {
            return {
                chart: {
                    type: 'bar',                    
                    foreColor: this.corLabel,    
                    stacked: true,        
                    toolbar: {
                        show: true
                    },   
                }, 
                plotOptions: {
                    bar: {
                        horizontal: true,
                        dataLabels: {
                            total: {
                                enabled: false,
                                offsetX: 0,
                                style: {
                                    fontSize: '20px',
                                    fontWeight: 900
                                }
                            }
                        }
                    },                    
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: "100%" 
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                legend: {
                    position: 'top',
                    fontSize: "20px"
                },               
                title: {
                    text: 'Envolvimento com Produto',
                    align: 'center',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize:  '20px',
                        fontWeight:  'bold',                        
                        color:  this.corLabel
                    },
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: "20px",                        
                        fontWeight: "bold"
                    }
                },                   
                yaxis: {
                    max: 100,
                    labels: {
                        show: false
                    }
                }                                                        
            }
        }, 
        optionsPizzaModules(){
            return {
                series: this.dsModuloDash.map((x) => parseFloat(x.PERCENT)),
                chart: {
                    type: 'donut',
                    toolbar: {
                        show: true
                    }, 
                    foreColor: this.corLabel 
                },
                stroke:{
                    show: false,                    
                },
                dataLabels: {
                    enabled: false,
                },
                legend: {
                    position: 'bottom'
                },
                colors: this.dsModuloDash.map((x) => x.corModule),                                
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: "100%"
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                title: {
                    text: 'Tempo x Módulo',
                    align: 'center',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize:  '20px',
                        fontWeight:  'bold',                        
                        color:  this.corLabel
                    },
                },                 
                labels: this.dsModuloDash.map((x) => x.modulo),             
            }
        }, 
        optionsBarTipo () {
            return {
                chart: {
                    type: 'bar',                    
                    foreColor: this.corLabel,    
                    stacked: true,        
                    toolbar: {
                        show: true
                    },   
                }, 
                plotOptions: {
                    bar: {
                        horizontal: true,
                        dataLabels: {
                            total: {
                                enabled: false,
                                offsetX: 0,
                                style: {
                                    fontSize: '20px',
                                    fontWeight: 900
                                }
                            }
                        }
                    },                    
                },                
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: "100%"
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                legend: {
                    position: 'top',
                    fontSize: '20px'                    
                },   
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: "20px",                        
                        fontWeight: "bold"
                    }
                },                               
                title: {
                    text: 'Tempo Empenhado',
                    align: 'center',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize:  '20px',
                        fontWeight:  'bold',                        
                        color:  this.corLabel
                    },
                },    
                yaxis: {
                    max: 100,
                    labels: {
                        show: false
                    }
                }                                  
            }
        },            
        barProdutoOutrosSeries (){             
            return this.dsPercentual.map((x) => {                
                return {
                    name: x.TipoPercent,
                    data: [parseFloat(x.PERCENT)]
                }})
        },  
        barTipoSeries(){
            let dsFiltrada = this.dsTipo.filter((x) => x.categoria !== 'TOTAL')           
            return dsFiltrada.map((x) => {
                return {
                    name: x.categoria,
                    data: [parseFloat(x.PERCENT)]
                }})
        },
        dataFimRules () {
            const rules = []
            if (this.dataToFilter.endDate.length === 0 ){
                const rule = v => !!v || 'Data fim precisa ser preenchida.'
                rules.push(rule)
            } else {                        
                const ruleCheck = (v) => {
                    if(v < this.dataToFilter.startDate) {
                        return 'Data final não pode ser menor que data inicial.'              
                    }
                }                
                rules.push(ruleCheck)                
            }
            return rules;                       
        },  
    },
    methods: {
        selectOnShift,
        changeData: async function (){  
              
            const response = await api.post('/dash/resultadotoggl', this.dataToFilter);
               
            let total = response.data.dadosProjeto.filter((x) => x.atividade == 'TOTAL')
            this.dsProjetos = response.data.dadosProjeto.filter((x) => x.atividade !== 'TOTAL');  
            this.dsProjetosDash = response.data.dadosProjeto.filter((x) => x.atividade !== 'TOTAL');
                      
            this.dsProjetos.push(total[0])

            total = response.data.dadosModulo.filter((x) => x.modulo == 'TOTAL')
            this.dsModulo = response.data.dadosModulo.filter((x) => x.modulo !== 'TOTAL');
            this.dsModuloDash = response.data.dadosModulo.filter((x) => x.modulo !== 'TOTAL');
            this.dsModulo.push(total[0])

            this.dsTipo = response.data.dadosTipo;
            this.dsPercentual = response.data.dadosPercentual;
            this.exibeFiltro = false;                   
        }
    },
    async mounted() {        
        if (this.dsProjetos.length === 0 || this.dsModulo.length === 0 || this.dsTipo.length === 0 || this.dsPercentual.length === 0 || this.dsUserToFilter.length === 0) {
            this.dataToFilter.option = "1"
            const response = await api.post('/dash/resultadotoggl', this.dataToFilter);
            const dataToFilters = await api.get('/datafiltertrack')
            this.dsUserToFilter = dataToFilters.data.users;
            this.dsAtividades = dataToFilters.data.atividades;
            this.dsCategorias = dataToFilters.data.categoria;
            this.dsModule = dataToFilters.data.modulo;
            this.dsTag = dataToFilters.data.tag;

            let total = response.data.dadosProjeto.filter((x) => x.atividade == 'TOTAL')
            this.dsProjetos = response.data.dadosProjeto.filter((x) => x.atividade !== 'TOTAL');  
            this.dsProjetosDash = response.data.dadosProjeto.filter((x) => x.atividade !== 'TOTAL');
                      
            this.dsProjetos.push(total[0])

            total = response.data.dadosModulo.filter((x) => x.modulo == 'TOTAL')
            this.dsModulo = response.data.dadosModulo.filter((x) => x.modulo !== 'TOTAL');
            this.dsModuloDash = response.data.dadosModulo.filter((x) => x.modulo !== 'TOTAL');
            this.dsModulo.push(total[0])

            this.dsTipo = response.data.dadosTipo;
            this.dsPercentual = response.data.dadosPercentual;
        }
        store.commit(M_MENU, `DashBoard Track`);
    },
})

