

import { columns } from '@/utils/columns';
import { store } from '@/store';
import { M_MENU } from '@/store/mutations-types';
import { msToTime } from '@/utils/msToTime';
import { defineComponent } from 'vue';
import { formsValidations } from '@/utils/formsValidations';
import { dateFunctions } from '@/utils/dateFunctions';
import api from '@/api/api';
import Grid from '@/components/Grid.vue';
import ModalCrud from '@/components/ModalCrud.vue';
import timeToMs from '@/utils/timeToMs';

export default defineComponent({
	name: "Track",
	components: {
		Grid,
		ModalCrud
	},
	data() {
		return {
			loadingGrid: false,
			cronometroRodando: false,
			editando: false,
			tracks: [],
			dateToGroup: [],
			totalAgrupador: [],
			textoModal: '',
			modalAberto: false,
			isLoading: false,
			tempoEsquecido: false,
			valid: true,
			dsAtividade: [],
			dsCategoria: [],
			dsModulos: [],
			dsTags: [],
			dsSugestoesText: [],
			dsSugestoes: [],
			modalExclusao: false,
			modalFilter: false,
			validFormFilter: true,
			isLoadingFilter: false,
			dsUsersToFilter: [],
			dataFilter: {
				startdate: '',
				enddate: '',
				user: 0,
			},
			dataTracks: {
				id: null,
				idAtividade: null,
				idCategoria: null,
				idModulo: null,
				descricao: '',
				dataHoraInicio: '',
				horaFim: '',
				total: '',
				idTag: null
			},
			horaDiferenca: ''
		};
	},
	computed: {
		dataFimRules() {
			const rules = []
			if (this.dataFilter.enddate.length === 0) {
				const rule = v => !!v || 'Data fim precisa ser preenchida.'
				rules.push(rule)
			} else {
				const ruleCheck = (v) => {
					if (v < this.dataFilter.startdate) {
						return 'Data final não pode ser menor que data início.'
					}
				}
				rules.push(ruleCheck)
			}
			return rules;
		},
		checkSupervisorParent() {
			const retorno = this.$store.state.infoUser.current.supervisor ? true : false
			return retorno
		}
	},
	methods: {
		updateDatasets: function (dataset) {
			this.dsAtividade = dataset.data.atividades;
			this.dsCategoria = dataset.data.categorias;
			this.dsModulos = dataset.data.modulos;
			this.dsTags = dataset.data.tags;
			this.dsUsersToFilter = dataset.data.users;
			this.dsSugestoesText = dataset.data.tracksToMap.map((x) => x.DESCRICAO);
			this.dsSugestoes = dataset.data.tracksToMap;
		},
		updateTracks: function (tracks) {
			this.tracks = tracks;
			let datas = this.tracks.map((x) => x.ACTIVITY_DATE)
			let totalizador = this.tracks.map((x) => x.SOMA)
			let trackers = this.tracks.map((x) => ({
				...x,
				position: datas.indexOf(x.ACTIVITY_DATE),
			}));
			this.tracks = trackers;
			this.dateToGroup = datas;
			this.totalAgrupador = totalizador;
		},
		zerarCronometro: function () {
			clearInterval(this.cronometro);
			this.cronometroRodando = false;
		},
		atualizaTable: function () {
			let datas = this.tracks.map((x) => x.ACTIVITY_DATE)
			let totalizador = this.tracks.map((x) => x.SOMA)
			let trackers = this.tracks.map((x) => ({
				...x,
				position: datas.indexOf(x.ACTIVITY_DATE),
			}));
			this.tracks = trackers;
			this.dateToGroup = datas;
			this.totalAgrupador = totalizador;
		},
		newTrack: async function () {
			this.modalAberto = true;
			this.textoModal = 'Novo Tempo';
			this.dataTracks.dataHoraInicio = dateFunctions.getDateTimewNow();
			const response = await api.get('/track/check');
			if (response.data.length) {
				this.editando = false;
				this.tempoEsquecido = false;
				const dados = response.data[0];
				this.dataTracks.id = dados.id;
				this.dataTracks.dataHoraInicio = dados.dataInicio;
				this.dataTracks.idAtividade = dados.idAtividade;
				this.dataTracks.idCategoria = dados.idCategoria;
				this.dataTracks.idModulo = dados.idModulo;
				this.dataTracks.descricao = dados.descricao;
				this.dataTracks.horaFim = dados.horaFim;
				this.dataTracks.total = dados.total;
				this.dataTracks.idTag = dados.idTag;
				this.cronometro = setInterval(() => {
					//@ts-ignore
					const diferenca = (new Date(dateFunctions.getDateTimewNow()) - new Date(this.dataTracks.dataHoraInicio).valueOf());
					this.dataTracks.total = msToTime(diferenca)
				}, 1000)
				this.cronometroRodando = true
				this.$toast.warning('Parece que você se esqueceu de finalizar uma tarefa.');
			} else {
				this.editando = false;
				this.tempoEsquecido = false;
				this.dataTracks.idAtividade = undefined;
				this.dataTracks.idCategoria = undefined;
				this.dataTracks.idModulo = undefined;
				this.dataTracks.descricao = undefined;
				this.dataTracks.horaFim = undefined;
				this.dataTracks.total = undefined;
				this.dataTracks.idTag = undefined;
				this.dataTracks.id = undefined;
				const response = await api.post('/track', this.dataTracks);
				let dados = response.data[0]
				this.dataTracks.id = dados.id;
				this.cronometro = setInterval(() => {
					//@ts-ignore
					const diferenca = (new Date(dateFunctions.getDateTimewNow()) - new Date(this.dataTracks.dataHoraInicio).valueOf());
					this.dataTracks.total = msToTime(diferenca);
				}, 1000)
				this.cronometroRodando = true
			}
			const element = document.querySelector('[tabindex="1"]')
			//@ts-ignore
			element.focus()

		},
		editTrack: async function (item) {
			try {
				this.editando = true;
				this.modalAberto = true
				this.textoModal = 'Editar Tempo'
				this.tempoEsquecido = true
				this.cronometroRodando = false
				const response = await api.get(`/track/${item.ID}`)
				const dados = response.data[0];
				this.dataTracks.id = dados.id;
				this.dataTracks.dataHoraInicio = dados.dataInicio;
				this.dataTracks.idAtividade = dados.idAtividade;
				this.dataTracks.idCategoria = dados.idCategoria;
				this.dataTracks.idModulo = dados.idModulo;
				this.dataTracks.descricao = dados.descricao;
				this.dataTracks.horaFim = dados.horaFim;
				this.dataTracks.total = dados.total;
				this.dataTracks.idTag = dados.idTag;
			} catch (error) {
				this.$toast.error('Falha ao editar tempo!');
			}

		},
		cloneTrack: async function (item) {
			try {
				const checkTrack = await api.get('/track/check');
				if (checkTrack.data.length) throw new Error('Tempo existente');
				this.modalAberto = true
				this.textoModal = 'Novo Tempo'
				this.editando = false;
				this.tempoEsquecido = false;
				const responseGet = await api.get(`/track/${item.ID}`)
				const dados = responseGet.data[0];
				this.dataTracks.dataHoraInicio = dateFunctions.getDateTimewNow();
				this.dataTracks.idAtividade = dados.idAtividade;
				this.dataTracks.idCategoria = dados.idCategoria;
				this.dataTracks.idModulo = dados.idModulo;
				this.dataTracks.descricao = dados.descricao;
				this.dataTracks.horaFim = '';
				this.dataTracks.total = '';
				this.dataTracks.idTag = dados.idTag;
				const response = await api.post('/track', this.dataTracks);
				this.dataTracks.id = response.data[0].id;
				this.cronometro = setInterval(() => {
					//@ts-ignore
					const diferenca = (new Date(dateFunctions.getDateTimewNow()) - new Date(this.dataTracks.dataHoraInicio).valueOf());
					this.dataTracks.total = msToTime(diferenca)
				}, 1000)
				this.cronometroRodando = true
			} catch (error) {
				console.log(error);
				this.$toast.error('Você tem um tempo pendente de finalização!');
			}

		},
		deleteTrack: function (item) {
			this.modalExclusao = true
			this.dataTracks.id = item.ID;
		},
		excluirTempo: async function () {
			try {
				const deleta = await api.delete(`/track/${this.dataTracks.id}`)
				const [response, datasets] = await Promise.all([
					api.get('/track'),
					api.get('/datatrack')
				])
				this.tracks = response.data;
				this.atualizaTable();
				this.updateDatasets(datasets);
				this.tracks = response.data;
				this.atualizaTable();
				this.modalExclusao = false;
				this.modalAberto = false;
				this.isLoading = false;
				this.cronometroRodando = false;
				this.zerarCronometro();
				this.$toast.success('Tempo excluído com sucesso!');
			} catch (error) {
				this.$toast.error('Falha ao excluir tempo!');
			}
		},
		closeModalExclusao: function () {
			if (this.textoModal === 'Editar Tempo') {
				this.zerarCronometro()
			}
			this.modalExclusao = false;
		},
		saveTrack: async function () {
			await this.$refs.descricaoCombo.blur();
			if (this.$refs.form.validate()) {
				this.isLoading = true;
				this.dataTracks.horaFim = this.dataTracks.horaFim ? this.dataTracks.horaFim : dateFunctions.getTimeNow();

				this.dataTracks.descricao = this.$refs.form.$el[4].value ? this.$refs.form.$el[4].value : this.dataTracks.descricao;
				clearInterval(this.cronometro)
				try {
					this.loadingGrid = true;
					if (this.dataTracks.total.length === 0) {
						this.$toast.warning('Formulário inválido! Verifique os dados.');
						this.isLoading = false;
						return;
					}
					if (this.dataTracks.total === '00:00:00') {
						this.$toast.warning('Formulário inválido! Verifique os dados.');
						this.isLoading = false;
						return;
					}
					await api.put('/track', this.dataTracks);
					const [response, datasets] = await Promise.all([
						api.get('/track'),
						api.get('/datatrack')
					]);
					this.tracks = response.data;
					this.atualizaTable();
					this.updateDatasets(datasets);
					this.modalAberto = false;
					this.isLoading = false;
					this.cronometroRodando = false;
					this.$toast.success('Tempo atualizado com sucesso!');
				} catch (error) {
					this.isLoading = false
					console.log(error)
					this.$toast.error('Falha ao comunicar com o banco de dados!');
				} finally {
					this.loadingGrid = false;
				}
			} else {
				this.$toast.warning('Formulário inválido! Verifique os dados.');
			}

		},
		changeHoraIni: function () {
			let tempoFinal = this.dataTracks.horaFim ? this.dataTracks.dataHoraInicio.slice(0, 11) + this.dataTracks.horaFim : dateFunctions.getDateTimewNow();

			if (new Date(this.dataTracks.dataHoraInicio).getTime() > new Date(tempoFinal).getTime()) {
				tempoFinal = new Date(new Date(tempoFinal).setDate(new Date(tempoFinal).getDate() + 1) - (new Date()).getTimezoneOffset() * 60000).toISOString().slice(0, 19)
			}
			//@ts-ignore
			const horaDiferenca = (new Date(tempoFinal) - new Date(this.dataTracks.dataHoraInicio).valueOf())
			this.dataTracks.total = msToTime(horaDiferenca)
		},
		changeHoraFim: function () {
			let tempoFinal = this.dataTracks.dataHoraInicio.slice(0, 11) + this.dataTracks.horaFim;
			if (new Date(this.dataTracks.dataHoraInicio).getTime() > new Date(tempoFinal).getTime()) {
				tempoFinal = new Date(new Date(tempoFinal).setDate(new Date(tempoFinal).getDate() + 1) - (new Date()).getTimezoneOffset() * 60000).toISOString().slice(0, 19);
			}
			//@ts-ignore

			//@ts-ignore
			const horaDiferenca = (new Date(tempoFinal) - new Date(this.dataTracks.dataHoraInicio).valueOf())
			this.dataTracks.total = msToTime(horaDiferenca)
		},
		changeTotal: function () {
			const horaInicio = this.dataTracks.dataHoraInicio.slice(11, 19)
			let calc = horaInicio.length === 5 ? horaInicio + ':00' : horaInicio
			const diferenca = msToTime(timeToMs(this.dataTracks.total) + timeToMs(calc))
			this.dataTracks.horaFim = diferenca
		},
		cronometroStopStart: function () {
			if (this.cronometroRodando) {
				clearInterval(this.cronometro);
				this.cronometroRodando = false;
				this.dataTracks.horaFim = dateFunctions.getTimeNow();
				this.tempoEsquecido = true;
			} else {
				this.dataTracks.horaFim = '';
				this.tempoEsquecido = false;
				this.cronometro = setInterval(() => {
					//@ts-ignore                    
					const diferenca = (new Date(dateFunctions.getDateTimewNow()) - new Date(this.dataTracks.dataHoraInicio).valueOf());
					this.dataTracks.total = msToTime(diferenca);
				}, 1000);
				this.cronometroRodando = true;
			}
		},
		changeFocus: function () {
			const element = document.querySelector('[tabindex="4"]')
			//@ts-ignore
			element.focus()
		},
		closeModalOnEsc: function () {
			if (this.textoModal === 'Novo Tempo') {
				this.modalExclusao = true
				this.idExclusao = this.idEdit
			} else if (this.textoModal === 'Editar Tempo') {
				this.modalAberto = false;
			}
		},
		changeFieldOnEnter: function (tabIndex: number) {
			let nextIndex = tabIndex === 11 ? 3 : tabIndex
			nextIndex += 1
			const element = document.querySelector(`[tabindex="${nextIndex}"]`)
			//@ts-ignore
			element.focus()
		},
		updateData: async function () {
			if (this.textoModal === 'Novo Tempo' && this.modalExclusao === false && this.dataTracks.id) {
				try {
					const dataForUpdate = { ...this.dataTracks };
					delete dataForUpdate.horaFim;
					delete dataForUpdate.total;
					const response = await api.put('/track', dataForUpdate)
				} catch (err) {
					if (!err.response.data.message.includes('An operation failed because it depends on one or more records that were required but not found')) {
						this.$toast.error('Falha ao salvar tempo parcial!');
					}
				}
			}
		},
		sugestaoInformacoes: function () {
			const tempoExistente = this.dsSugestoes.find((x) => x.DESCRICAO === this.dataTracks.descricao)
			if (tempoExistente) {
				this.dataTracks.idAtividade = tempoExistente.CODIGO_ATIVIDADE;
				this.dataTracks.idCategoria = tempoExistente.CODIGO_CATEGORIA;
				this.dataTracks.idModulo = tempoExistente.CODIGO_MODULO;
				this.dataTracks.idTag = tempoExistente.CODIGO_TAG;
			}
		},
		handlerSuggest: function () {
			this.sugestaoInformacoes();
			this.updateData();
		},
		handlerHoraInicio: function () {
			this.changeHoraIni();
			this.updateData();
		},
		closeModalFilter: function () {
			this.modalFilter = false;
		},
		filterTracks: async function () {
			try {
				if (this.$refs.formFilter.validate()) {
					this.isLoadingFilter = true;
					const user = this.dataFilter.user ? `&user=${this.dataFilter.user}` : ''
					const response = await api.get(`/track?startdate=${this.dataFilter.startdate}&enddate=${this.dataFilter.enddate}${user}`)
					this.updateTracks(response.data)
					this.modalFilter = false
				} else {
					this.$toast.warning('Formulário inválido! Verifique os dados.');
				}

			} catch (err) {
				console.log(err)
				this.$toast.error('Falha ao filtrar dados.');
			} finally {
				this.isLoadingFilter = false

			}
		}
	},
	async mounted() {

		if (this.tracks.length === 0) {
			this.loadingGrid = true;
			const response = await api.get('/track');
			this.updateTracks(response.data)
			this.loadingGrid = false;
		}
		if (this.dsAtividade.length === 0) {
			const response = await api.get('/datatrack');
			this.updateDatasets(response);
		}
		window.addEventListener('beforeunload', function (e) {
			e.preventDefault();
			e.returnValue = '';
		});
		store.commit(M_MENU, "Track");
	},
	setup() {
		return {
			columns,
			formsValidations,
			dateFunctions
		}
	}


})

