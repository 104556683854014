

import { defineComponent } from 'vue';
import { store } from '@/store';
import { M_MENU } from '@/store/mutations-types';
import Grid from '@/components/Grid.vue'
import api from '@/api/api';
import ModalCrud from '@/components/ModalCrud.vue';

export default defineComponent({
    name: 'Clientes',
    components: {
        ModalCrud,
        Grid
    },    
    data() {
        return {
            loadingGrid: false,
            idEdit: 0,
            textoModal: '',
            modalAberto: false,
            isLoading: false,
            valid: true,
            razaoSocial: '',
            razaoSocialRules: [
                v => !!v || 'Razão Social precisa ser preenchida.',
            ],
            cidades: '',
            dsCidade: [],
            cidadesRules: [
                v => !!v || 'Cidade precisa ser preenchida.'
            ],
            status: true,            
            clients: [],
            columns: [
                {
                    text: 'ID',
                    align: 'start',
                    sortable: true,
                    value: 'CODIGOCLIENTE',
                },
                {
                    text: 'Razão Social',
                    sortable: true,
                    value: 'NOMEFANTASIACLIENTE',
                },
                {
                    text: 'Status',
                    sortable: true,
                    value: 'CODIGO_STATUS',
                },
                {
                    text: 'Ações',
                    value: 'actions',
                    sortable: false
                }
            ]
        }
    },
    methods: {
        newClient: function() {
            this.idEdit = 0
            this.modalAberto = true
            this.textoModal = 'Novo Cliente'            
            this.razaoSocial = '',
            this.status = true
            this.cidades = ''
        },
        editClient: async function(item) {
            this.modalAberto = true
            this.textoModal = 'Editar Cliente'
            const response = await api.get(`/clients/${item.CODIGOCLIENTE}`)
            const { CODIGOCLIENTE, NOMEFANTASIACLIENTE, CODIGO_STATUS, CIDADE } = response.data[0]            
            this.idEdit = CODIGOCLIENTE
            this.razaoSocial = NOMEFANTASIACLIENTE,
            this.status = CODIGO_STATUS === 'A' ? true : false
            this.cidades = CIDADE
        },
        closeModal: function() {
            this.modalAberto = false,
            this.textoModal = ''
        },
        saveClient: async function(){
            if (this.$refs.form.validate()){
                const data = {
                    nome: this.razaoSocial,
                    idCidade: this.dsCidade.find((x) => x.NOMECIDADE === this.cidades).CODIGOCIDADE,
                    telefone: "",
                    status: this.status === true ? 'A' : 'I'
                }
                if (this.idEdit > 0) {
                    try {
                        data['id'] = this.idEdit                        
                        this.isLoading = true
                        const response = await api.put('/clients', data);
                        this.clients.map((x, i) => {
                            if (x.CODIGOCLIENTE === this.idEdit) {                                                             
                                this.clients[i].CODIGOCLIENTE = response.data.CODIGOCLIENTE,
                                this.clients[i].NOMEFANTASIACLIENTE = response.data.NOMEFANTASIACLIENTE,
                                this.clients[i].CODIGO_STATUS = response.data.CODIGO_STATUS === 'A' ? 'Ativo' : 'Inativo'                                
                                return
                            }
                        })                 
                        this.isLoading = false;
                        this.modalAberto = false;
                        this.textoModal = '';
                        this.razaoSocial = '';
                        this.cidades = '';
                        this.status = true;
                        this.$toast.success(`Cliente ${data.nome} alterado com sucesso!`);                        
                    } catch (error) {
                        this.isLoading = false;                        
                        this.$toast.error('Falha ao alterar cliente!');                        
                    }                    
                } else {
                    try {
                        this.isLoading = true
                        const response = await api.post('/clients', data);                    
                        this.clients.unshift({
                            CODIGOCLIENTE: response.data.CODIGOCLIENTE,
                            NOMEFANTASIACLIENTE: response.data.NOMEFANTASIACLIENTE,
                            CODIGO_STATUS: response.data.CODIGO_STATUS === 'A' ? 'Ativo' : 'Inativo'
                        });                
                        this.isLoading = false;
                        this.modalAberto = false;
                        this.textoModal = '';
                        this.$toast.success('Novo Cliente criado com sucesso!');                        
                    } catch (error) {
                        this.isLoading = false;
                        this.$toast.error('Falha ao criar cliente!');                        
                    }
                }
            } else {
                this.$toast.warning('Formulário inválido! Verifique os dados.');
            }         
        }
    },
    async mounted() {
        this.loadingGrid = true;
        if (this.clients.length === 0 || this.dsCidade.length === 0) {
            const [clients, cities] = await Promise.all(
                [
                    api.get('/clients'),
                    api.get('cities')
                ]
            )
            this.clients = clients.data;
            this.dsCidade = cities.data;
        }
        store.commit(M_MENU, 'Clientes');
        this.loadingGrid = false;
    },
})

