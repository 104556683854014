

import { store } from '@/store';
import { M_MENU } from '@/store/mutations-types';
import { defineComponent } from 'vue';
import { columns } from '@/utils/columns';
import api from '@/api/api';
import { formsValidations } from '@/utils/formsValidations';
import ModalCrud from '@/components/ModalCrud.vue';
import ModernGrid from '@/components/ModernGrid.vue';

export default defineComponent({
	name: 'ControleFinanceiro',
	data() {
		return {
			table: {
				isLoading: false,
				dataset: [],
				actions: [
					{
						icon: 'mdi-view-dashboard-edit-outline',
						color: 'primary',
						action: 'verLote'
					},
					{
						icon: 'mdi-trash-can-outline',
						color: 'red',
						action: 'deadActiveLote'
					}
				]
			},
			modals: {
				novoLote: {
					data: {
						form: {
							idColaborador: null,
							idStatus: true
						},
						inputs: {
							dsColaboradores: []
						}
					},
					visible: false,
					isLoading: true,
					isValid: false
				}
			}
		};
	},
	async mounted() {
		try {
			this.table.isLoading = true;
			const { data } = await api.get('/financial-control');
			this.table.dataset = data;
			store.commit(M_MENU, "Controle Financeiro");
			if (!(this.$store.state.infoUser.current.supervisor) && !(this.$store.state.infoUser.current.departamento === 4)) {
				const { data } = await api.get('/financial-control-user');
				this.$router.push(`/controle-financeiro/${data.ID}`)
			}
		} catch (err) {
			this.$toast.error(err.message ?? 'Falha ao comunicar com o back-end!');
			if (err.message === 'Não há nenhum lote criado para seu usuário, contate o departamento administrativo.') {
				this.$router.push('/home');
			}
		} finally {
			this.table.isLoading = false;
		}
	},
	methods: {
		deadActiveLote: async function (item) {
			try {
				this.table.isLoading = true;
				await api.patch(`/financial-control/${item.id}`);
				const { data } = await api.get('/financial-control');
				this.table.dataset = data;
				this.$toast.success("Registro alterado!");
			} catch (error) {
				this.$toast.error("Falha ao comunicar com o banco de dados!");
			} finally {
				this.table.isLoading = false;
			}
		},
		verLote: function (item) {
			this.$router.push({
				path: `/controle-financeiro/${item.id}`,
			})
		},
		showModal: async function () {
			try {
				this.table.isLoading = true;
				this.modals.novoLote.isLoading = true;
				const { data } = await api.get('/financial-control-data');
				this.modals.novoLote.data.inputs.dsColaboradores = data.colaboradores;
				this.modals.novoLote.visible = true;
			} catch (error) {

				this.$toast.error("Falha ao comunicar com o banco de dados!");
			} finally {
				this.table.isLoading = false;
				this.modals.novoLote.isLoading = false;
			}
		},
		closeModal: async function () {
			try {
				this.table.isLoading = true;
				this.modals.novoLote.data = {
					form: {
						idColaborador: null,
						idStatus: true
					},
					inputs: {
						dsColaboradores: []
					}
				};
				this.modals.novoLote.visible = false;
				this.modals.novoLote.isLoading = false;
				const { data } = await api.get('/financial-control');
				this.table.dataset = data;
			} catch (error) {

				this.$toast.error("Falha ao comunicar com o banco de dados!");
			} finally {
				this.table.isLoading = false;
			}
		},
		saveNewLote: async function () {
			try {
				this.modals.novoLote.isLoading = true;
				this.modals.novoLote.data.form.idStatus ? this.modals.novoLote.data.form.idStatus = 'A' : this.modals.novoLote.data.form.idStatus = 'I';
				await api.post('/financial-control', this.modals.novoLote.data.form);
				this.$toast.success("Novo lote criado!");
				await this.closeModal();
			} catch (error) {
				this.$toast.error("Falha ao comunicar com o banco de dados!");
			} finally {
				this.modals.novoLote.isLoading = false;
			}
		}
	},
	setup() {
		return {
			columns,
			formsValidations
		};
	},
	components: {
		ModalCrud,
		ModernGrid
	}
})

