import LoginView from '@/views/Login/LoginView.vue';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import HomeView from '@/views/Home/HomeView.vue';
import ClientesView from '@/views/Cadastros/Clientes/ClientesView.vue';
import CarrosView from '@/views/Cadastros/Carros/CarrosView.vue';
import TarefasView from '@/views/Cadastros/Tarefas/TarefasView.vue';
import UsuariosView from '@/views/Cadastros/Usuarios/UsuariosView.vue';
import OndeEstou from '@/views/Lancamentos/OndeEstou/OndeEstou.vue';
import DashSprintView from '@/views/Dashboards/PED/DashSprintView.vue';
import DashPlanejadoRealizadoView from '@/views/Dashboards/PED/DashPlanejadoRealizadoView.vue';
import DashEmergentesProjetosView from '@/views/Dashboards/PED/DashEmergentesProjetosView.vue';
import DashTrackView from '@/views/Dashboards/PED/DashTrackView.vue';
import DashTrackAnalytic from '@/views/Dashboards/PED/DashAnalyticTrackView.vue'
import PendenciasPedView from '@/views/Dashboards/PED/PendenciasPedView.vue';
import CalendarioView from '@/views/Dashboards/Consultoria/CalendarioView.vue';
import PainelOndeEstouView from '@/views/Paineis/Consultoria/PainelOndeEstouView.vue';
import TrackProjetos from '@/views/Lancamentos/TrackProjetos/TrackProjetos.vue';
import NFSeExpress from '@/views/Lancamentos/NFSe/NFSeExpress.vue';
import ControleFinanceiro from '@/views/Lancamentos/ControleFinanceiro/ControleFinanceiro.vue';
import auth from '@/services/auth';
import RegioesView from '@/views/Cadastros/Regioes/RegioesView.vue';
import TrackRefactoringVue from '@/views/Lancamentos/Track/TrackRefactoring.vue';
import DashGanttProjectsMap from '@/views/Dashboards/PED/DashGanttProjectsMapView.vue';
import ControleFinanceiroMovimentacaoVue from '@/views/Lancamentos/ControleFinanceiro/ControleFinanceiroMovimentacao.vue';
import DashBoletosVue from '@/views/Dashboards/Financeiro/DashBoletos.vue';
import PendenciasView from '@/views/Paineis/Geral/PendenciasView.vue';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Login',
    component: LoginView,
    beforeEnter: auth.authed
  },
  {
    path: '/home',
    name: 'Home',
    component: HomeView,
    beforeEnter: auth.auth,
    meta: {
      canLauchUserTask: true
    }
  },
  {
    path: '/clientes',
    name: 'Clientes',
    component: ClientesView,
    beforeEnter: auth.auth,
    meta: {
      canLauchUserTask: true
    }
  },
  {
    path: '/carros',
    name: 'carros',
    component: CarrosView,
    beforeEnter: auth.auth,
    meta: {
      canLauchUserTask: true
    }
  },
  {
    path: '/regioes',
    name: 'regioes',
    component: RegioesView,
    beforeEnter: auth.auth,
    meta: {
      canLauchUserTask: true
    }
  },
  {
    path: '/atividades',
    name: 'atividades',
    component: TarefasView,
    beforeEnter: auth.auth,
    meta: {
      canLauchUserTask: true
    }
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component: UsuariosView,
    beforeEnter: auth.auth,
    meta: {
      canLauchUserTask: true
    }
  },
  {
    path: '/onde-estou',
    name: 'onde-Estou',
    component: OndeEstou,
    beforeEnter: auth.auth,
    meta: {
      canLauchUserTask: true
    }
  },
  {
    path: '/track',
    name: 'track',
    component: TrackRefactoringVue,
    beforeEnter: auth.auth,
    meta: {
      canLauchUserTask: true
    }
  },
  {
    path: '/mapeamento-projetos',
    name: 'mapeamento-projetos',
    component: TrackProjetos,
    beforeEnter: auth.auth,
    meta: {
      canLauchUserTask: true
    }
  },
  {
    path: '/nfse',
    name: 'nfse',
    component: NFSeExpress,
    beforeEnter: auth.auth,
    meta: {
      canLauchUserTask: true
    }
  },
  {
    path: '/controle-financeiro',
    name: 'controle-financeiro',
    component: ControleFinanceiro,
    beforeEnter: auth.auth,
    meta: {
      canLauchUserTask: true
    }
  },
  {
    path: '/controle-financeiro/:id',
    name: 'controle-financeiro-lote',
    component: ControleFinanceiroMovimentacaoVue,
    beforeEnter: auth.auth
  },
  {
    path: '/dash-sprint',
    name: 'dash-sprints',
    component: DashSprintView,
    beforeEnter: auth.auth
  },
  {
    path: '/dash-planejado-efetivado',
    name: 'dash-planejado-efetivado',
    component: DashPlanejadoRealizadoView,
    beforeEnter: auth.auth
  },
  {
    path: '/dash-emergentes-projeto',
    name: 'dash-emergentes-projeto',
    component: DashEmergentesProjetosView,
    beforeEnter: auth.auth,    
  },
  {
    path: '/dash-track',
    name: 'dash-track',
    component: DashTrackView,
    beforeEnter: auth.auth
  },
  {
    path: '/dash-track-analytic',
    name: 'dash-track-analytic',
    component: DashTrackAnalytic,
    beforeEnter: auth.auth
  },
  {
    path: '/dash-track-projects',
    name: 'dash-track-projects',
    component: DashGanttProjectsMap,
    beforeEnter: auth.auth
  },
  {
    path: '/dash-financeiro',
    name: 'dash-financeiro',
    component: DashBoletosVue,
    beforeEnter: auth.auth
  },
  {
    path: '/pendencias-ped',
    name: 'pendencias-ped',
    component: PendenciasView,
    beforeEnter: auth.auth
  },
  {
    path: '/calendario',
    name: 'calendario',
    component: CalendarioView,
    beforeEnter: auth.auth
  },
  {
    path: '/painel-onde-estou',
    name: 'painel-onde-estou',
    component: PainelOndeEstouView,
    beforeEnter: auth.auth
  }

]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
