

import api from '@/api/api';
import Grid from '@/components/Grid.vue';
import ModalCrud from '@/components/ModalCrud.vue';
import { store } from '@/store';
import { M_MENU } from '@/store/mutations-types';
import { defineComponent } from 'vue';

export default defineComponent({
    name: "CarrosView",
    components: {
        Grid,
        ModalCrud
    },
    data() {
        return {
            loadingGrid: false,
            idEdit: 0,
            textoModal: '',
            modalAberto: false,
            isLoading: false,
            valid: true,
            placa: '',
            placaRules: [
                v => !!v || 'Placa precisa ser preenchida.',
            ], 
            numero: '',
            numeroRules: [
                v => !!v || 'Número precisa ser preenchido.',
            ],
            descricao: '',
            descricaoRules: [
                v => !!v || 'Descrição precisa ser preenchida.',
            ],  
            status: true,                        
            carros: [],
            columns: [
                {
                    text: 'ID',
                    align: 'start',
                    sortable: true,
                    value: 'CODIGOCARRO',
                },
                {
                    text: 'Placa Carro',
                    sortable: true,
                    value: 'PLACACARRO',
                },
                {
                    text: 'Carro',
                    sortable: true,
                    value: 'DESCRICAOCARRO',
                },
                {
                    text: 'Num. Carro',
                    sortable: true,
                    value: 'NUMEROCARRO',
                },
                {
                    text: 'Status',
                    sortable: true,
                    value: 'CODIGO_STATUS',
                },
                {
                    text: 'Ações',
                    value: 'actions',
                    sortable: false
                }
            ]
        };
    },
    methods: {
        newCar: function () {            
            this.modalAberto = true
            this.textoModal = 'Novo Carro'
            this.idEdit = 0
            this.placa = '',
            this.descricao = '',
            this.numero = '',
            this.status = true
        },
        editCar: async function (item) {
            this.modalAberto = true
            this.textoModal = 'Editar Carro'
            const response = await api.get(`/cars/${item.CODIGOCARRO}`)
            const { CODIGOCARRO, PLACACARRO, DESCRICAOCARRO, NUMEROCARRO,  CODIGO_STATUS } = response.data         
            this.idEdit = CODIGOCARRO
            this.placa = PLACACARRO,
            this.descricao = DESCRICAOCARRO,
            this.numero = NUMEROCARRO,
            this.status = CODIGO_STATUS === 'A' ? true : false            
        },
        closeModal: function() {
            this.modalAberto = false,
            this.textoModal = ''
        },
        saveCar: async function(){
            if (this.$refs.form.validate()){
                const data = {
                    placa: this.placa,
                    numero: this.numero,
                    descricao: this.descricao,
                    status: this.status === true ? 'A' : 'I'
                }
                if (this.idEdit > 0) {
                    try {
                        data['id'] = this.idEdit                        
                        this.isLoading = true
                        const response = await api.put('/cars', data);
                        const dataset = await api.get("/cars");                        
                        this.carros = dataset.data;                                
                        this.isLoading = false;
                        this.modalAberto = false;
                        this.textoModal = '';
                        this.razaoSocial = '';
                        this.cidades = '';
                        this.status = true;
                        this.$toast.success(`Carro ${data.descricao} alterado com sucesso!`);
                    } catch (error) {
                        this.isLoading = false;                        
                        this.$toast.error('Falha ao alterar carro!');                        
                    }                    
                } else {
                    try {
                        this.isLoading = true
                        const response = await api.post('/cars', data);                    
                        const dataset = await api.get("/cars");
                        this.carros = dataset.data;   
                        this.isLoading = false;
                        this.modalAberto = false;
                        this.textoModal = '';
                        this.$toast.success('Novo carro criado com sucesso!');                        
                    } catch (error) {
                        this.isLoading = false;                        
                        this.$toast.error('Falha ao criar novo carro!');                        
                    }
                }
            } else {
                this.$toast.warning('Formulário inválido! Verifique os dados.');                
            }           
        }            
    },    
    async mounted() {
        this.loadingGrid = true;
        if (this.carros.length === 0) {
            const response = await api.get("/cars");
            this.carros = response.data;
        }
        store.commit(M_MENU, "Carros");
        this.loadingGrid = false;
    },
})

