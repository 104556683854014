

import { defineComponent } from 'vue';

export default defineComponent({
	name: 'Grid',
	data() {
		return {
			search: '',
			parent: ''
		}
	},
	props: {
		headers: {
			type: [],
			required: true
		},
		items: {
			type: [],
			required: true
		},
		textGroup: {
			type: [],
			required: false
		},
		totalGroup: {
			type: [],
			required: false
		},
		groupBy: {
			type: String,
			required: false
		},
		newAction: {
			type: Function,
			required: false
		},
		editAction: {
			type: Function,
			required: false
		},
		canEdit: {
			type: Boolean,
			required: false,
			default: true
		},
		itemsPerPage: {
			type: Number,
			required: false
		},
		cloneAction: {
			type: Function,
			required: false
		},
		deleteAction: {
			type: Function,
			required: false
		},
		filterAction: {
			type: Function,
			required: false
		},
		defaultOrder: {
			type: String,
			required: false
		},
		loading: {
			type: Boolean,
			require: true
		},
		totalAnalyticTrack: {
			type: Array,
			required: false
		}
	},
	computed: {
		checkSupervisorParent() {
			const retorno = this.parent === 'Track' && this.$store.state.infoUser.current.supervisor ? true : false;
			return retorno;
		}
	},
	methods: {
		toggleAll() {
			Object.keys(this.$refs).forEach(k => {
				this.$refs[k].$el.click()
			})
		},
	},
	mounted() {
		this.parent = this.$parent.$options.name;
	}
})
