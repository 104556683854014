


import { defineComponent } from 'vue';

export default defineComponent({
	name: 'ModernGrid',
	data() {
		return {
			search: '',
		}
	},
	props: {
		columns: {
			type: [],
			required: true
		},
		dataset: {
			type: [],
			required: true
		},
		itemsPerPage: {
			type: Number,
			required: false
		},
		loading: {
			type: Boolean,
			require: true
		},
		actions: {
			type: [],
			required: false,
			default: []
		}
	},
	methods: {
		invokeParentAction(action, item) {
			this.$parent[action](item);
		}
	}
})

