

import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ModalCrud',
    data() {
        return {
            dialog: false
        }
    },
    props: {
        title: {
            type: String,
            required: true
        },      
        opened: {
            type: Boolean,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        },
        closeOnEsc: {
            type: Function,
            required: false
        }

    },
    computed: {
        show () {
            return this.opened
        }
    }
})


