import { render, staticRenderFns } from "./ModernGrid.vue?vue&type=template&id=1c42efaa&scoped=true&"
import script from "./ModernGrid.vue?vue&type=script&lang=ts&"
export * from "./ModernGrid.vue?vue&type=script&lang=ts&"
import style0 from "./ModernGrid.vue?vue&type=style&index=0&id=1c42efaa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c42efaa",
  null
  
)

export default component.exports