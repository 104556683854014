

import api from '@/api/api';
import { defineComponent } from 'vue';
import { dateFunctions } from '@/utils/dateFunctions'
import VueApexCharts from 'vue-apexcharts';
import { ApexOptions } from 'apexcharts';
import { store } from '@/store';
import { M_MENU } from '@/store/mutations-types';

export default defineComponent({
	name: 'DashBoletos',
	data() {
		return {
			dataset: [],
			isLoading: false,
			dataFilter: {
				idDrillDown: 1,
				period: '',
				company: '',
				idOptionCentroCusto: 1,
				drillDownOption: ''
			},
			monthDetail: {
				title: '',
				currentMonthExt: null,
				actualMonth: null
			},
			modalDrilDown: {
				visible: false,
				dataset: [],
				columns: []
			}
		}
	},
	methods: {
		async updateDate() {
			try {
				this.$store.state.loading = true
				this.isLoading = true;
				const currentDate = new Date();
				this.dataFilter.period = this.convertPeriodToNumber(this.monthDetail.currentMonthExt)
				const { data } = await api.post('/dash/boletos', this.dataFilter);
				this.dataset = data;
			} catch (error) {
				this.$toast.error('Falha ao carregar os dados!');
			} finally {
				this.isLoading = false;
				this.$store.state.loading = false;
			}
		},
		convertPeriodToNumber(period) {
			return period.getFullYear() + (period.getMonth() + 1).toString().padStart(2, '0')
		},
		mountMonth(inicialize?: boolean) {
			try {
				this.monthDetail.actualMonth = new Date();
				if (inicialize) this.monthDetail.currentMonthExt = new Date();
				this.monthDetail.title = this.dateFunctions.formatYearMonth(this.monthDetail.currentMonthExt.getMonth(), this.monthDetail.currentMonthExt.getFullYear())
			} catch (error) {
				this.$toast.error('Falha ao carregar as datas!')
			}
		},
		async previousNext(previousOrNext: boolean) {
			let actualMonth = this.monthDetail.currentMonthExt;
			if (previousOrNext) {
				if (actualMonth.getMonth() === 11) {
					this.monthDetail.currentMonthExt.setMonth(0);
					this.monthDetail.currentMonthExt.setFullYear(this.monthDetail.currentMonthExt.getFullYear() + 1);
				} else {
					this.monthDetail.currentMonthExt.setMonth(this.monthDetail.currentMonthExt.getMonth() + 1);
				}
			} else {
				if (actualMonth.getMonth() === 0) {
					this.monthDetail.currentMonthExt.setMonth(11);
					this.monthDetail.currentMonthExt.setFullYear(this.monthDetail.currentMonthExt.getFullYear() - 1);
				} else {
					this.monthDetail.currentMonthExt.setMonth(this.monthDetail.currentMonthExt.getMonth() - 1);
				}
			}
			this.mountMonth();
			await this.updateDate();
		},
		async changeDashCentroDeCustos() {
			try {
				this.$store.state.loading = true;
				this.dataset.receveidByCenter = []
				const { data } = await api.post('/dash/boletos/centro-custos', this.dataFilter);
				this.dataset.receveidByCenter = data.receveidByCenter;
			} catch (error) {
				this.$toast.error('Falha ao carregar os dados!')
			} finally {
				this.$store.state.loading = false;
			}
		},
		async handleModalDrillDown(typeOfModal: string, company?: string) {
			try {
				this.$store.state.loading = true;

				this.dataFilter.drillDownOption = typeOfModal;
				this.dataFilter.company = company;

				const { data } = await api.post('/dash/boletos/drill-down', this.dataFilter);

				this.modalDrilDown.dataset = data.dataset;
				this.modalDrilDown.columns = data.columns;

				this.modalDrilDown.visible = true;
			} catch (error) {
				this.$toast.error('Falha ao carregar os dados!');
			} finally {
				this.$store.state.loading = false;
			}
		},
		closeModalDrillDown() {
			this.dataFilter.drillDownOption = '';
			this.dataFilter.company = '';
			this.modalDrilDown.visible = false;
		},
		async setCurrentMonth() {
			try {
				this.monthDetail.currentMonthExt = this.monthDetail.actualMonth;
				this.mountMonth();
				await this.updateDate()
			} catch (error) {
				this.$toast.error('Falha ao carregar as datas!')
			}
		}
	},
	computed: {
		corLabel() {
			return this.$vuetify.theme.dark ? '#FFFFFF' : '#000000'
		},
		dashVencimentos(): { chartOptions: ApexOptions, series: any } {
			return {
				chartOptions: {
					chart: {
						type: 'pie',
						foreColor: this.corLabel,
						toolbar: {
							show: false
						},
						height: "100%"

					},
					stroke: {
						show: false,
					},
					dataLabels: {
						enabled: true,
						background: {
							enabled: true,
							foreColor: '#000000',
							borderRadius: 5
						},
						formatter: (value) => {
							return value.toLocaleString('pt-br', {
								maximumFractionDigits: 2,
								minimumFractionDigits: 2
							}) + '%'
						},
						style: {
							fontSize: '13px',
							colors: ['#FFFFFF'],
							fontWeight: 'bold',
						}
					},
					legend: {
						position: 'bottom'
					},
					tooltip: {
						y: {
							formatter: (value) => {
								return value.toLocaleString('pt-br', {
									maximumFractionDigits: 2,
									minimumFractionDigits: 2
								})
							}
						}
					},
					responsive: [{
						breakpoint: 480,
						options: {
							chart: {
								width: "100%"
							},
							legend: {
								position: 'bottom'
							}
						}
					}],
					title: {
						text: 'Análise Vencimento',
						align: 'center',
						margin: 10,
						offsetX: 0,
						offsetY: 0,
						floating: false,
						style: {
							fontSize: '14px',
							fontWeight: 'bold',
							color: this.corLabel
						},
					},
					theme: {
						palette: 'palette1'
					},
					labels: ['Vencidas', 'Vencendo Hoje', 'À Vencer'],
					plotOptions: {
						pie: {
							donut: {
								size: '85%',
								labels: {
									show: true,
									name: {
										show: false,
										fontSize: '15px',
									},
									value: {
										fontWeight: 'bold',
										formatter: (value: string) => {
											return Number(value).toLocaleString('pt-br')
										}
									},
									total: {
										fontSize: '15px',
										show: true,
										formatter(w) {
											const totalizer = w.config.series.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
											return totalizer.toLocaleString('pt-br')
										},
									}
								}
							}
						}
					},
				},
				series: [Number(this.dataset.vencidasVencendoHoje.vencidas), Number(this.dataset.vencidasVencendoHoje.vencendoHoje), Number(this.dataset.vencidasVencendoHoje.aVencer)]
			}
		},
		dashCentroCustos(): { chartOptions: ApexOptions, series: any } {
			return {
				chartOptions: {
					chart: {
						type: 'pie',
						foreColor: this.corLabel,
						toolbar: {
							show: false
						}
					},
					stroke: {
						show: false,
					},
					dataLabels: {
						enabled: true,
						background: {
							enabled: true,
							foreColor: '#000000',
							borderRadius: 5
						},
						formatter: (value) => {
							return value.toLocaleString('pt-br', {
								maximumFractionDigits: 2,
								minimumFractionDigits: 2
							}) + '%'
						},
						style: {
							fontSize: '13px',
							colors: ['#FFFFFF'],
							fontWeight: 'bold',
						}
					},
					tooltip: {
						enabled: true,
						y: {
							formatter: (value) => {
								return Number(value).toLocaleString('pt-br', {
									maximumFractionDigits: 2,
									minimumFractionDigits: 2
								})
							}
						}
					},
					responsive: [{
						breakpoint: 480,
						options: {
							chart: {
								width: "100%"
							},
							legend: {
								position: 'bottom'
							}
						}
					}],
					legend: {
						position: 'bottom'
					},
					title: {
						text: ` ${Number(this.dataFilter.idOptionCentroCusto) === 1 ? 'Recebido' : 'À receber'} por Centro de Custos`,
						align: 'center',
						margin: 10,
						offsetX: 0,
						offsetY: 0,
						floating: false,
						style: {
							fontSize: '14px',
							fontWeight: 'bold',
							color: this.corLabel
						},
					},
					labels: this.dataset.receveidByCenter.length ? this.dataset.receveidByCenter.map(x => x.tipoConta) : [],
					fill: {
						opacity: 1
					},
					theme: {
						palette: 'palette3' // upto palette10
					},
					plotOptions: {
						pie: {
							donut: {
								size: '85%',
								labels: {
									show: true,
									name: {
										show: false,
										fontSize: '15px',
									},
									value: {
										fontWeight: 'bold',
										formatter: (value: string) => {
											return Number(value).toLocaleString('pt-br')
										}
									},
									total: {
										fontSize: '15px',
										show: true,
										formatter(w) {
											const totalizer = w.config.series.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
											return totalizer.toLocaleString('pt-br')
										},
									}
								}
							}
						}
					},
				},
				series: this.dataset.receveidByCenter.length ? this.dataset.receveidByCenter.map(x => Number(x.valorRecebido)) : []
			}
		},
		dashByCompany(): { chartOptions: ApexOptions, series: any } {
			return {
				chartOptions: {
					grid: {
						show: false
					},
					chart: {
						type: 'bar',
						foreColor: this.corLabel,
						toolbar: {
							show: false
						},
						events: {
							dataPointSelection: async (event, chartContext, config) => {
								await this.handleModalDrillDown('dash-company', config.w.config.xaxis.categories[config.dataPointIndex])
								//await this.handleChangeDashboard(config.w.config.xaxis.categories[config.dataPointIndex])
								//await this.handleChangeDashboard(config.w.config.xaxis.categories[config.dataPointIndex])
							}
						}
					},
					tooltip: {
						y: {
							formatter: (value) => {
								return value.toLocaleString('pt-br', {
									maximumFractionDigits: 2,
									minimumFractionDigits: 2
								})
							}
						}
					},
					responsive: [{
						breakpoint: 480,
						options: {
							chart: {
								width: "100%",
							},
							yaxis: {
								labels: {
									show: true,
									align: 'left',
									minWidth: 0,
									maxWidth: 500,
									offsetX: 300,
									offsetY: -45,
									style: {
										fontSize: '0.8rem',
										fontWeight: 500,
									}
								},
								show: true,
								floating: true,
							}
						}
					}],
					yaxis: {
						labels: {
							show: true,
							align: 'left',
							minWidth: 0,
							maxWidth: 500,
							offsetX: 300,
							offsetY: (this.$vuetify.breakpoint.height * 5.3 / 100) * -1,
							style: {
								fontSize: '0.8rem',
								fontWeight: 500,
							}
						},
						show: true,
						floating: true
					},
					xaxis: {
						categories: this.dataset.totalizerByCompay.length ? this.dataset.totalizerByCompay.map(x => x.company) : [],
						labels: {
							show: false,
							trim: true,
						},
						axisBorder: {
							show: false
						},
						axisTicks: {
							show: false
						},
					},
					title: {
						text: 'Análise por Empresa',
						align: 'center',
						margin: 0,
						offsetX: 0,
						offsetY: 0,
						floating: false,
						style: {
							fontSize: '20px',
							fontWeight: 'bold',
							color: this.corLabel
						},
					},
					legend: {
						position: 'top'
					},
					dataLabels: {
						enabled: true,
						formatter: (value, opts) => {							
							return value.toLocaleString('pt-br', {
								maximumFractionDigits: 2,
								minimumFractionDigits: 2
							})
						}
					},
					plotOptions: {
						bar: {
							horizontal: true,
							borderRadius: 2,
							borderRadiusApplication: 'around',
							rangeBarOverlap: false,
							colors: {
								backgroundBarColors: ['#546E7A'],
								backgroundBarOpacity: 0.25,
								backgroundBarRadius: 2,
							},
						},
					}
				},
				series: [
					{
						name: 'Valor Gerado',
						data: this.dataset.totalizerByCompay ? this.dataset.totalizerByCompay.map(x => Number(x.valorGerado)) : [],
						color: '#2196F3'
					},
					{
						name: 'Valor Recebido',
						data: this.dataset.totalizerByCompay ? this.dataset.totalizerByCompay.map(x => Number(x.valorRecebido)) : [],
						color: '#4CAF50'
					},
					{
						name: 'Valor à Receber',
						data: this.dataset.totalizerByCompay ? this.dataset.totalizerByCompay.map(x => Number(x.valorAReceber)) : [],
						color: '#FEB019'
					}
				]
			}
		}
	},
	watch: {
		'dataFilter.idOptionCentroCusto': async function (newVal, oldVal) {
			await this.changeDashCentroDeCustos()
		}
	},
	components: {
		apexchart: VueApexCharts,
	},
	async mounted() {
		this.mountMonth(true);
		await this.updateDate();
		store.commit(M_MENU, `Financeiro - Visão Geral`);
	},
	setup() {
		return {
			dateFunctions
		}
	}
})

