
import api from '@/api/api';
import { store } from '@/store';
import { M_MENU } from '@/store/mutations-types';
import { defineComponent } from 'vue';
import { columns } from '@/utils/columns';
import Grid from '@/components/Grid.vue';
import ModalCrud from '@/components/ModalCrud.vue';
import selectOnShift from '@/utils/selectOnShift';

export default defineComponent({
    name: "TrackProjetos",
    data() {
        return {
            modalFilterOpened: false,
            modalFilterLoading: false,
            loadingGrid: false,
            isLoading: false,
            valid: true,
            dataTrackProjetos: {
                id: 0,
                idTipoProjeto: 0,
                descricao: "",
                idStatusRedmine: 0,
                prioridade: 0,
                progresso: 0,
                idTarefaRedmine: null,
                observacao: "",
                dataIni: "",
                dataFim: "",
                idStatus: true
            },
            dadosFiltros: {
                statusRedmine: [],
                prioridadeTarefas: []
            },
            columns: columns,
            trackProjetos: [],
            dsTipoProjeto: [],
            dsStatusTarefas: [],
            dsPrioridadeToFilter: [
                {
                    id: 0,
                    descricao: '0'
                },
                {
                    id: 1,
                    descricao: '1'
                },
                {
                    id: 2,
                    descricao: '2'
                },
                {
                    id: 3,
                    descricao: '3'
                },
                {
                    id: 4,
                    descricao: '4'
                },
                {
                    id: 5,
                    descricao: '5'
                }
            ],
            dsPrioridade: [
                0,
                1,
                2,
                3,
                4,
                5
            ],
            fieldValidatePercentual: [
                v => !(v < 0 || v > 100) || "Não ser menor que 0 nem maior que 100",
            ],
            fieldValidatePrioridade: [
                v => !(v === null) || "Esse campo precisa ser preenchido.",
            ],
            fieldValidate: [
                v => !!v || "Esse campo precisa ser preenchido.",
            ],
            modalAberto: false,
            textoModal: ""
        };
    },
    methods: {
        selectOnShift,
        newTrackProjeto: function () {
            this.dataTrackProjetos = {
                id: 0,
                idTipoProjeto: 0,
                descricao: "",
                idStatusRedmine: 0,
                prioridade: null,
                progresso: 0,
                idTarefaRedmine: null,
                observacao: "",
                dataIni: "",
                dataFim: "",
                idStatus: true
            },
                this.modalAberto = true;
            this.textoModal = "Novo Mapeamento";
        },
        editTrackProjeto: async function (item) {
            this.modalAberto = true;
            this.textoModal = "Editar Mapeamento";
			this.isLoading = true;
            const response = await api.get(`/trackprojetos/${item.id}`);	
            this.dataTrackProjetos.id = response.data.ID;
            this.dataTrackProjetos.idTipoProjeto = response.data.ID_TIPO_PROJETO;
            this.dataTrackProjetos.descricao = response.data.DESCRICAO;
            this.dataTrackProjetos.idStatusRedmine = response.data.ID_STATUS_REDMINE;
            this.dataTrackProjetos.prioridade = response.data.PRIORIDADE;
            this.dataTrackProjetos.progresso = response.data.PROGRESSO;
            this.dataTrackProjetos.idTarefaRedmine = response.data.ID_TAREFA_REDMINE;
            this.dataTrackProjetos.observacao = response.data.OBSERVACAO;
            this.dataTrackProjetos.dataIni = response.data.DATAINI ? response.data.DATAINI.slice(0, 10) : '';
            this.dataTrackProjetos.dataFim = response.data.DATAFIM ? response.data.DATAFIM.slice(0, 10) : '';
            this.dataTrackProjetos.idStatus = response.data.ID_STATUS === "A" ? true : false;
			this.isLoading = false;
        },
        saveTrackProjetos: async function () {
            try {
                this.isLoading = true;
                if (this.$refs.form.validate()) {
                    const [metodo, mensagem] = this.dataTrackProjetos.id ? ['put', 'alterado'] : ['post', 'criado'];
                    this.dataTrackProjetos.idStatus = this.dataTrackProjetos.idStatus ? 'A' : 'I'
                    const operation = await api[metodo]('/trackprojetos', this.dataTrackProjetos);
                    const response = await api.get('/trackprojetos');
                    this.trackProjetos = response.data;
                    this.$toast.success(`Mapeamento ${mensagem} com sucesso!`);
                    this.modalAberto = false;
                } else {
                    this.$toast.warning('Formulário inválido! Verifique os dados.');
                }
            } catch (err) {
                this.$toast.error('Falha ao comunicar com o banco de dados!');
                console.log(err);
            } finally {
                this.isLoading = false
            }
        },
        handleFilter: async function () {
            try {
                this.modalFilterLoading = true;
                const response = await api.post('/trackprojetos/filter', this.dadosFiltros);
                this.trackProjetos = response.data;
                this.modalFilterOpened = false;
            } catch (err) {
                console.log(err)
                this.$toast.error('Falha ao comunicar com o banco de dados!');
            } finally {
                this.modalFilterLoading = false;
            }
        }
    },
	watch: {
		'modalAberto': function(newValue, oldValue){
			if (newValue) {
				this.dataTrackProjetos =  {
					id: 0,
					idTipoProjeto: 0,
					descricao: "",
					idStatusRedmine: 0,
					prioridade: 0,
					progresso: 0,
					idTarefaRedmine: null,
					observacao: "",
					dataIni: "",
					dataFim: "",
					idStatus: true
				}
			}
		}
	},
    async mounted() {
        this.loadingGrid = true;
        const [tracks, dados] = await Promise.all([
            api.get("/trackprojetos"),
            api.get("/trackprojetos/data")
        ]);
        this.trackProjetos = tracks.data;
        this.dsTipoProjeto = dados.data.tipoProjeto;
        this.dsStatusTarefas = dados.data.status;
        this.loadingGrid = false;
        store.commit(M_MENU, "Mapeamento de Projetos");
    },
    components: { Grid, ModalCrud }
})

